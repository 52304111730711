import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgTerrassendach01 from "../../assets/leistungen/terrasse01.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Terrassenüberdachungen"
      Content="Sie möchten bei jeder Witterung im Freien sitzen? Dann bauen wir Ihnen ein Dach über Ihre Terrasse."
    />
    <LeistungBox
      Color="gray"
      Image={imgTerrassendach01}
      IsImageLeft={true}
      Title=""
      Content="Es gibt viele Möglichkeiten ein Terrassendach herzustellen, beispielsweise ein Pultdach mit Schweißbahn, Ziegel, Folie, Metall, Glaselementen oder Doppelstegplatten.
        In den meisten Fällen bewährt es sich für eine Terrasse mit Doppelstegplatten zu entscheiden, da diese das Sonnenlicht durchlassen und die Terrasse somit frei wirken lassen. Ein weiterer Vorteil bietet die Leichtbauweise eines Systems mit Doppelstegplatten. Dabei wird die Statik des Gebäudes nur minimal beansprucht. Die Mindestdachneigung bei Stegplatten liegt gerade mal bei 5°, das sind circa 9 cm/m.
        <p />
        Mit einer Unterkonstruktion aus feinem Sichtholz wird Ihr Terrassendach zum Highlight der Terrasse."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
